import * as React from "react";
import { Divider, Typography, List, Collapse } from "antd";

import HeaderComponent from "../../components/Header";

import { Helmet } from "react-helmet";

import FooterComponent from "../../components/Footer";

import favicon from "../../images/favicon.ico";
import Wrapper from "../../components/Wrapper";
import bannerImage from "../../images/banner-no-text.jpg";
import "./about-us.scss";

import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

const { Panel } = Collapse;

const AboutUsPage = () => {
  const { t } = useTranslation();

  const visi = [
    `${t("visi-1")}`,
    `${t("visi-2")}`,
    `${t("visi-3")}`,
    `${t("visi-4")}`,
  ];

  const misi = [
    `${t("misi-1")}`,
    `${t("misi-2")}`,
    `${t("misi-3")}`,
    `${t("misi-4")}`,
  ];

  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Prabu Cahaya Abadi | About Us</title>
        <meta
          name="description"
          content="PT. Prabu Cahaya Abadi (PCA) adalah perusahaan swasta nasional yang didirikan pada tahun 2018 oleh beberapa profesional yang sudah berpengalaman lebih dari dua dekade di bidang perdagangan minyak internasional maupun domestik."
        />
        <link rel="icon" href={favicon} />
      </Helmet>
      <HeaderComponent />
      <div>
        <img
          src={bannerImage}
          alt="banner"
          style={{ maxHeight: 200, width: "100%", objectFit: "cover" }}
        />
      </div>
      <Wrapper>
        <div className="about-us">
          <Typography className="about-us__title">About Us</Typography>
          <Divider className="about-us__divider" />
          <div className="about-us__content">
            <Typography className="about-us__content__description">
              {t("aboutUs-1")}
            </Typography>
            <Typography className="about-us__content__description">
              {t("aboutUs-2")}
            </Typography>
            <Typography className="about-us__content__description">
              {t("aboutUs-3")}
            </Typography>
          </div>
          <div className="about-us__visiMisi">
            <Collapse defaultActiveKey={[1, 2]}>
              <Panel header={t("visi")} key="1">
                <List
                  size="large"
                  dataSource={visi}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
              <Panel header={t("misi")} key="2" style={{ marginTop: "20px" }}>
                <List
                  size="large"
                  dataSource={misi}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </Panel>
            </Collapse>
          </div>
        </div>
      </Wrapper>
      <FooterComponent />
    </div>
  );
};

export default AboutUsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["about-us"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
